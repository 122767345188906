"use client";

import { Fragment } from "react";
import Link from "next/link";
import { Popover, Transition } from "@headlessui/react";
import clsx from "clsx";
import { Button } from "../../components/Button";
import { Container } from "../../components/Container";
import { Logo } from "../../components/Logo";
import { NavLink } from "../../components/NavLink";
import { useUser } from "@clerk/nextjs";
function MobileNavLink({
  href,
  children
}) {
  return <Popover.Button as={Link} href={href} className="block w-full p-2" data-sentry-element="unknown" data-sentry-component="MobileNavLink" data-sentry-source-file="Header.jsx">
      {children}
    </Popover.Button>;
}
function MobileNavIcon({
  open
}) {
  return <svg aria-hidden="true" className="h-3.5 w-3.5 overflow-visible stroke-slate-700" fill="none" strokeWidth={2} strokeLinecap="round" data-sentry-element="svg" data-sentry-component="MobileNavIcon" data-sentry-source-file="Header.jsx">
      <path d="M0 1H14M0 7H14M0 13H14" className={clsx("origin-center transition", open && "scale-90 opacity-0")} data-sentry-element="path" data-sentry-source-file="Header.jsx" />
      <path d="M2 2L12 12M12 2L2 12" className={clsx("origin-center transition", !open && "scale-90 opacity-0")} data-sentry-element="path" data-sentry-source-file="Header.jsx" />
    </svg>;
}
function MobileNavigation() {
  return <Popover data-sentry-element="Popover" data-sentry-component="MobileNavigation" data-sentry-source-file="Header.jsx">
      <Popover.Button className="relative z-10 flex h-8 w-8 items-center justify-center [&:not(:focus-visible)]:focus:outline-none" aria-label="Toggle Navigation" data-sentry-element="unknown" data-sentry-source-file="Header.jsx">
        {({
        open
      }) => <MobileNavIcon open={open} />}
      </Popover.Button>
      <Transition.Root data-sentry-element="unknown" data-sentry-source-file="Header.jsx">
        <Transition.Child as={Fragment} enter="duration-150 ease-out" enterFrom="opacity-0" enterTo="opacity-100" leave="duration-150 ease-in" leaveFrom="opacity-100" leaveTo="opacity-0" data-sentry-element="unknown" data-sentry-source-file="Header.jsx">
          <Popover.Overlay className="fixed inset-0 bg-slate-300/50" data-sentry-element="unknown" data-sentry-source-file="Header.jsx" />
        </Transition.Child>
        <Transition.Child as={Fragment} enter="duration-150 ease-out" enterFrom="opacity-0 scale-95" enterTo="opacity-100 scale-100" leave="duration-100 ease-in" leaveFrom="opacity-100 scale-100" leaveTo="opacity-0 scale-95" data-sentry-element="unknown" data-sentry-source-file="Header.jsx">
          <Popover.Panel as="div" className="absolute inset-x-0 top-full mt-4 flex origin-top flex-col rounded-2xl bg-white p-4 text-lg tracking-tight text-slate-900 shadow-xl ring-1 ring-slate-900/5" data-sentry-element="unknown" data-sentry-source-file="Header.jsx">
            {/*<MobileNavLink href="#features">Features</MobileNavLink>*/}
            {/*<MobileNavLink href="#testimonials">Testimonials</MobileNavLink>*/}
            {/*<MobileNavLink href="#pricing">Pricing</MobileNavLink>*/}
            <hr className="m-2 border-slate-300/40" />
            <MobileNavLink href="/login" data-sentry-element="MobileNavLink" data-sentry-source-file="Header.jsx">Sign in</MobileNavLink>
          </Popover.Panel>
        </Transition.Child>
      </Transition.Root>
    </Popover>;
}
export function Header() {
  const {
    isLoaded,
    isSignedIn,
    user
  } = useUser();
  return <header className="py-10" data-sentry-component="Header" data-sentry-source-file="Header.jsx">
      <Container data-sentry-element="Container" data-sentry-source-file="Header.jsx">
        <nav className="relative z-50 flex justify-between">
          <div className="flex items-center md:gap-x-12">
            <Link href="/" aria-label="Home" data-sentry-element="Link" data-sentry-source-file="Header.jsx">
              <Logo className="h-10 w-auto rounded-full" data-sentry-element="Logo" data-sentry-source-file="Header.jsx" />
            </Link>
            {/*<div className="hidden md:flex md:gap-x-6">*/}
            {/*  <NavLink href="#features">Features</NavLink>*/}
            {/*  <NavLink href="#testimonials">Testimonials</NavLink>*/}
            {/*  <NavLink href="#pricing">Pricing</NavLink>*/}
            {/*</div>*/}
          </div>
          {!user?.id ? <div className="flex items-center gap-x-5 md:gap-x-8">
              <div className="hidden md:block">
                <NavLink href="/sign-in">Sign in</NavLink>
              </div>
              <Button href="/sign-up" color="blue">
                <span>
                  Get started <span className="hidden lg:inline">today</span>
                </span>
              </Button>
              <div className="-mr-1 md:hidden">
                <MobileNavigation />
              </div>
            </div> : <div className="flex items-center gap-x-5 md:gap-x-8">
              <Button href="/sign-out" color="blue">
                <span>Sign out</span>
              </Button>
            </div>}
        </nav>
      </Container>
    </header>;
}