"use client";

import { Button } from "../components/Button";
import { Container } from "../components/Container";
import { useEffect, useState } from "react";
import { logAnalyticsEvent } from "~/libs/3rd-party/firebase/FirebaseAnalyticsProvider";
import { useUser } from "@clerk/nextjs";
export function Hero() {
  const [isCreating, setIsCreating] = useState(false);
  const {
    user
  } = useUser();
  return <Container className="pb-16 pt-20 text-center lg:pt-32" data-sentry-element="Container" data-sentry-component="Hero" data-sentry-source-file="Hero.jsx">
      <h1 className="mx-auto max-w-4xl font-display text-5xl font-medium tracking-tight text-slate-900 sm:text-7xl">
        <span className="relative">Unofficial</span>
        <br />
        <span className="relative whitespace-nowrap text-blue-600">
          <svg aria-hidden="true" viewBox="0 0 418 42" className="absolute left-0 top-2/3 h-[0.58em] w-full fill-blue-300/70" preserveAspectRatio="none" data-sentry-element="svg" data-sentry-source-file="Hero.jsx">
            <path d="M203.371.916c-26.013-2.078-76.686 1.963-124.73 9.946L67.3 12.749C35.421 18.062 18.2 21.766 6.004 25.934 1.244 27.561.828 27.778.874 28.61c.07 1.214.828 1.121 9.595-1.176 9.072-2.377 17.15-3.92 39.246-7.496C123.565 7.986 157.869 4.492 195.942 5.046c7.461.108 19.25 1.696 19.17 2.582-.107 1.183-7.874 4.31-25.75 10.366-21.992 7.45-35.43 12.534-36.701 13.884-2.173 2.308-.202 4.407 4.442 4.734 2.654.187 3.263.157 15.593-.78 35.401-2.686 57.944-3.488 88.365-3.143 46.327.526 75.721 2.23 130.788 7.584 19.787 1.924 20.814 1.98 24.557 1.332l.066-.011c1.201-.203 1.53-1.825.399-2.335-2.911-1.31-4.893-1.604-22.048-3.261-57.509-5.556-87.871-7.36-132.059-7.842-23.239-.254-33.617-.116-50.627.674-11.629.54-42.371 2.494-46.696 2.967-2.359.259 8.133-3.625 26.504-9.81 23.239-7.825 27.934-10.149 28.304-14.005.417-4.348-3.529-6-16.878-7.066Z" data-sentry-element="path" data-sentry-source-file="Hero.jsx" />
          </svg>
          <span className="relative">Disney Lorcana</span>
        </span>
        <br />
        Table top simulator.
      </h1>
      <p className="mx-auto mt-6 max-w-2xl text-lg tracking-tight text-slate-700">
        Simulate a tabletop game with a virtual environment. Free and simple.
      </p>
      <div className="mt-10 flex justify-center gap-x-6">
        {user ? isCreating ? <Button disabled={true}>Loading...</Button> :
      // TODO: We should make the route /game work on vercel
      <>
              <Button href={`/lobby`} onClick={() => {
          setIsCreating(true);
          logAnalyticsEvent("game_created", {
            gameId: user.uid
          });
        }}>
                Create a game
              </Button>
              <Button href={`/lobbies`} variant="outline">
                Find lobby
              </Button>
            </> : <Button href="/sign-up">Create an account</Button>}
      </div>

      {/*<div className="mt-8 lg:mt-44">*/}
      {/*  <button*/}
      {/*    type="button"*/}
      {/*    onClick={() => router.push("https://play.lorcanito.com")}*/}
      {/*    className="relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"*/}
      {/*  >*/}
      {/*    <span className="mt-2 block text-sm font-bold text-gray-900">*/}
      {/*      Note for regular players:*/}
      {/*    </span>*/}
      {/*    <span className="mt-2 block text-sm font-semibold text-gray-900">*/}
      {/*      we are implementing game lobby and matchmaking features. If you'd*/}
      {/*      like to help us shaping the future of this project, please use this*/}
      {/*      link instead{" "}*/}
      {/*      <Link className="text-indigo-500" href="https://play.lorcanito.com">*/}
      {/*        play.lorcanito.com*/}
      {/*      </Link>*/}
      {/*      .*/}
      {/*      <br />*/}
      {/*      Note that this is a beta version and it may have bugs. You're always*/}
      {/*      welcome to play on the stable version at play.lorcanito.com*/}
      {/*    </span>*/}
      {/*  </button>*/}
      {/*</div>*/}
    </Container>;
}