export function Logo(props) {
  return <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="300.000000pt" height="300.000000pt" viewBox="0 0 300.000000 300.000000" preserveAspectRatio="xMidYMid meet" {...props} data-sentry-element="svg" data-sentry-component="Logo" data-sentry-source-file="Logo.jsx">
      <g transform="translate(0.000000,300.000000) scale(0.100000,-0.100000)" fill="#fff" stroke="none" data-sentry-element="g" data-sentry-source-file="Logo.jsx">
        <path fill="#000" d="M0 1500 l0 -1500 1500 0 1500 0 0 1500 0 1500 -1500 0 -1500 0 0
        -1500z m1525 1045 c68 -763 140 -932 441 -1025 38 -12 76 -24 84 -27 8 -2 -24
    -15 -73 -27 -251 -66 -385 -205 -448 -466 -20 -82 -24 -91 -31 -65 -50 210
    -85 284 -177 376 -79 79 -169 127 -298 158 -80 19 -80 22 -3 41 87 22 205 87
    250 137 78 89 128 224 164 443 16 99 50 401 61 553 4 49 8 87 10 85 2 -2 11
    -84 20 -183z m-160 -83 c-26 -122 -58 -233 -85 -297 -14 -33 -28 -48 -54 -59
    -94 -39 -219 -145 -279 -236 l-29 -45 7 39 c30 177 116 371 222 500 42 52 217
    205 234 206 4 0 -3 -48 -16 -108z m435 76 c157 -42 327 -138 450 -254 63 -60
    160 -173 160 -187 0 -3 -35 9 -77 27 -145 61 -302 88 -492 85 -98 -2 -125 1
    -131 12 -11 19 -66 243 -75 303 l-8 49 49 -7 c27 -4 83 -16 124 -28z m-642
    -70 c-164 -164 -252 -375 -292 -701 -18 -147 -40 -183 -148 -245 -27 -15 -48
    -29 -48 -32 0 -3 21 -16 47 -30 82 -43 117 -86 147 -176 34 -104 85 -188 161
    -266 l60 -62 -74 41 c-111 61 -181 115 -278 217 -130 134 -213 268 -267 430
    l-26 79 26 86 c101 332 357 597 686 711 46 16 84 29 86 29 1 1 -35 -36 -80
    -81z m918 -323 c135 -25 266 -77 365 -142 42 -28 74 -91 113 -224 13 -47 31
    -102 40 -122 17 -43 88 -114 139 -142 21 -11 37 -22 37 -25 0 -3 -18 -15 -39
    -27 -65 -37 -101 -73 -130 -131 l-28 -55 -7 44 c-18 117 -89 300 -154 398 -52
    79 -156 186 -226 234 -89 60 -216 116 -329 144 -104 25 -145 50 -110 65 26 12
    236 1 329 -17z m-93 -129 c131 -58 278 -179 355 -293 100 -148 167 -341 188
    -543 6 -59 3 -72 -24 -133 -54 -119 -138 -236 -244 -339 -54 -54 -103 -98
    -107 -98 -4 0 3 41 16 92 40 160 56 315 48 478 -3 80 -7 163 -8 185 -2 39 -1
    40 65 80 37 22 67 43 68 46 0 3 -19 16 -42 29 -84 47 -125 95 -153 179 -44
    133 -127 252 -235 335 -40 30 -28 27 73 -18z m-1513 -546 c103 -204 287 -387
    503 -500 51 -27 125 -58 165 -70 40 -12 71 -24 69 -27 -3 -2 -51 4 -107 12
    -192 31 -352 90 -495 183 -117 76 -141 100 -155 153 -35 130 -68 179 -156 231
    -29 18 -54 35 -54 38 1 3 26 21 57 40 30 19 67 50 82 67 l26 33 16 -48 c9 -26
    31 -76 49 -112z m1697 -312 c-6 -189 -28 -310 -85 -463 -60 -164 -65 -170
    -150 -205 -146 -62 -251 -83 -412 -84 -105 -1 -168 4 -228 17 -320 69 -598
    275 -745 552 -56 106 -55 104 -16 74 56 -43 195 -109 308 -146 126 -41 283
    -69 444 -78 136 -9 120 5 176 -152 21 -57 41 -105 45 -106 4 -1 25 44 46 99
    59 159 57 156 120 170 214 48 414 234 476 442 8 28 18 52 21 52 3 0 3 -78 0
    -172z" data-sentry-element="path" data-sentry-source-file="Logo.jsx" />
      </g>
    </svg>;
}