"use client";

import React, { useEffect, useRef } from "react";
import { AuthProvider, FirebaseAppProvider, FirestoreProvider, useFirebaseApp } from "reactfire";
import { config } from "~/libs/3rd-party/firebase/config";
import FirebaseAnalyticsProvider, { AnalyticsTrackingEventsProvider } from "~/libs/3rd-party/firebase/FirebaseAnalyticsProvider";
import { getAuth, signInWithCustomToken } from "firebase/auth";
import { RealtimeDatabaseProvider } from "~/libs/3rd-party/firebase/RealtimeDatabase";
import { shouldConnectAuthEmulator } from "~/libs/3rd-party/firebase/emulator";
import { init } from "~/libs/3rd-party/firebase/index";
import { getAnalytics } from "firebase/analytics";
import { Firestore, getFirestore, initializeFirestore, persistentLocalCache, persistentSingleTabManager } from "firebase/firestore";
import { getApp } from "firebase/app";
import * as Sentry from "@sentry/nextjs";
import { useInstantAuth } from "~/libs/3rd-party/instant/instant.client";
import { useAuth } from "@clerk/nextjs";
export const Providers: React.FC<{
  children: React.ReactNode;
}> = ({
  children
}) => {
  const {
    getToken,
    userId
  } = useAuth();
  const app = useFirebaseApp(); // a parent component contains a `FirebaseAppProvider`
  const auth = getAuth(app);
  const analytics = typeof window !== "undefined" ? getAnalytics(app) : undefined;
  let firestore = useRef<Firestore>();
  useInstantAuth();
  useEffect(() => {
    const signIntoFirebaseWithClerk = async () => {
      const token = await getToken({
        template: "integration_firebase"
      });
      await signInWithCustomToken(auth, token || "");
    };
    if (userId && !auth.currentUser) {
      console.log("User Signing into Firebase with Clerk: ");
      signIntoFirebaseWithClerk().catch(e => {
        console.error("User signing into Firebase with Clerk failed: ", e);
        Sentry.captureException(e);
      });
    }
  }, [userId, auth.currentUser]);
  try {
    if (!firestore.current) {
      firestore.current = initializeFirestore(getApp(), {
        ignoreUndefinedProperties: true,
        localCache: persistentLocalCache({
          tabManager: persistentSingleTabManager(undefined)
        })
      });
    }
  } catch (e) {
    console.error(e);
    Sentry.captureException(e);
    firestore.current = getFirestore(app);
  }

  // TODO: Think about this later
  const emulatorEnabled = shouldConnectAuthEmulator();
  init(app, emulatorEnabled);

  // any child components will be able to use `useUser`, `useDatabaseObjectData`, etc
  return <FirestoreProvider sdk={firestore.current} data-sentry-element="FirestoreProvider" data-sentry-component="Providers" data-sentry-source-file="FirebaseProvider.tsx">
      <AuthProvider sdk={auth} data-sentry-element="AuthProvider" data-sentry-source-file="FirebaseProvider.tsx">
        <FirebaseAnalyticsProvider sdk={analytics} data-sentry-element="FirebaseAnalyticsProvider" data-sentry-source-file="FirebaseProvider.tsx">
          <AnalyticsTrackingEventsProvider data-sentry-element="AnalyticsTrackingEventsProvider" data-sentry-source-file="FirebaseProvider.tsx">
            <RealtimeDatabaseProvider data-sentry-element="RealtimeDatabaseProvider" data-sentry-source-file="FirebaseProvider.tsx">{children}</RealtimeDatabaseProvider>
          </AnalyticsTrackingEventsProvider>
        </FirebaseAnalyticsProvider>
      </AuthProvider>
    </FirestoreProvider>;
};
export const FirebaseProvider: React.FC<{
  children: React.ReactNode;
}> = ({
  children
}) => {
  return <FirebaseAppProvider firebaseConfig={config} data-sentry-element="FirebaseAppProvider" data-sentry-component="FirebaseProvider" data-sentry-source-file="FirebaseProvider.tsx">
      <Providers data-sentry-element="Providers" data-sentry-source-file="FirebaseProvider.tsx">{children}</Providers>
    </FirebaseAppProvider>;
};